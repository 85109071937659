import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static values = {
		context: Object
	}

	static targets = ['notice'];

	brevo = null;
	disabled = false;
	delay = 12;
	enableTimer = true;

	connect() {
		this.brevo = (...args) => window.BrevoConversations(...args);
		if (!this.brevo) {
			console.log("brevo not available");
			this.disabled = true;
			return;
		}

		if (this.contextValue) {
			for (const [k, v] of Object.entries(this.contextValue)) {
				this.update(k, v);
			}
		}

		//if (!this.shouldStartTimer()) {
		//	return;
		//}

		//this.startTimer();
		//this.resetTimerOnUserActivity();
		//this.setupOpenListener();
	}

	update(key, value) {
		if (this.disabled || !this.brevo) {
			console.log("brevo controller disabled or not available");
			return;
		}

		const update = {};
		update[key] = value;

		this.brevo('updateIntegrationData', { ...update })
	}

	openChatWindow(mobile = false) {
		if (mobile) {
			// opens for both mobile and desktop.
			// optional because on mobile, the chat window covers the whole screen.
			this.brevo('openChat', true);
		} else {
			this.brevo('expandWidget', true);
		}
	}

	disableTimer() {
		clearTimeout(this.chatTimer);
		this.enableTimer = false;
	}

	startTimer(overrideDelay = null) {
		const delay = overrideDelay ?? this.delay;
		this.chatTimer = setTimeout(() => {
			this.openChatWindow();

			if (this.hasNoticeTarget) {
				this.addNotice();
			}

			this.setChatNoticeShown();

			this.disableTimer();
		}, delay * 1000);
	}

	resetTimerOnUserActivity() {
		this.activityHandler = () => {
			if (!this.enableTimer) {
				return;
			}

			clearTimeout(this.chatTimer);
			this.startTimer(this.delay / 2);
		};

		["keydown", "touchstart", "touchmove", "click"].forEach(event => {
			document.addEventListener(event, this.activityHandler, { passive: true });
		});
	}

	onChatOpened() {
		this.disableTimer();
		this.hideNotice();
	}

	setupOpenListener() {
		const sleep = ms => new Promise(r => setTimeout(r, ms));

		const waitForElement = (selector) => {
			return new Promise(async (resolve, reject) => {
				for (let i = 0; i < 10; i++) {
					let el = document.querySelector(selector);
					if (el) {
						resolve(el);
					}

					await sleep(1000);
				}

				reject("element not found, giving up");
			});
		};

		const observeClassChanges = (element, callback) => {
			const observer = new MutationObserver(mutations => {
				mutations.forEach(mutation => {
					if (mutation.attributeName === "class") {
						callback(element, element.classList);
					}
				});
			});

			observer.observe(element, { attributes: true, attributeFilter: ["class"] });
			return observer;
		}

		waitForElement("#brevo-conversations")
			.then((el) => {
				const observer = observeClassChanges(el, (el, classes) => {
					if (classes.contains("brevo-conversations--expanded")) {
						this.onChatOpened();

						observer.disconnect();
					}
				});
			})
			.catch(err => {
				console.error(err);
			});
	}


	addNotice() {
		if (!this.hasNoticeTarget) {
			return;
		}

		const parent = document.getElementById("brevo-conversations");
		const el = this.noticeTarget;
		Object.assign(el.style, {
			position: "absolute",
			top: "-2rem",
			minWidth: "250px",
			right: 0,
			zIndex: 1,
		});
		parent.prepend(el);
		const rect = el.getBoundingClientRect();
		Object.assign(el.style, {
			top: `-${rect.height + 20}px`,
		});
	}

	hideNotice() {
		if (!this.hasNoticeTarget) {
			return;
		}

		this.noticeTarget.classList.add("d-none");
	}

	setChatNoticeShown() {
		localStorage.setItem("chatNoticeShownV1", Date.now());
	}

	shouldStartTimer() {
		const noticeShown = localStorage.getItem("chatNoticeShownV1");
		if (!noticeShown) {
			return true;
		}

		if (Date.now() - noticeShown > 30*60*1000) {
			return true;
		}

		return false;
	}
}
